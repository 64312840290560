import { toCamelCase } from '@/utils/string';

export const CONTEST_REGISTER_TYPE = {
  STUDENT: 'student',
  INDEPENDENT_BUSINESS: 'independent-business',
  GENERAL: 'general',
};

export const CONTEST_REGISTER_TYPE_PASCAL = {
  STUDENT: 'STUDENT',
  INDEPENDENT_BUSINESS: 'INDEPENDENT_BUSINESS',
  GENERAL: 'GENERAL',
};

export const ASSOCIATION_TYPE = {
  [CONTEST_REGISTER_TYPE.STUDENT]: 'EDUCATIONAL_INSTITUTION',
  [CONTEST_REGISTER_TYPE.INDEPENDENT_BUSINESS]: 'KOBA_BRANCH',
  [CONTEST_REGISTER_TYPE.GENERAL]: 'KOBA_BRANCH',
};

export const PLAYER_INFO_TITLE = {
  [CONTEST_REGISTER_TYPE.STUDENT]: '학생부',
  [CONTEST_REGISTER_TYPE_PASCAL.STUDENT]: '학생부',
  [CONTEST_REGISTER_TYPE.INDEPENDENT_BUSINESS]: '소상공인부',
  [CONTEST_REGISTER_TYPE_PASCAL.INDEPENDENT_BUSINESS]: '소상공인부',
  [CONTEST_REGISTER_TYPE.GENERAL]: '일반부',
  [CONTEST_REGISTER_TYPE_PASCAL.GENERAL]: '일반부',
};

export const GENERAL_ATTR = {
  name: {
    label: '지회장명 또는 기술강사명',
    placeholder: '성명',
  },
};

export const STUDENT_ATTR = {
  name: {
    label: '선생님 또는 교수명',
    placeholder: '성명',
  },
};

/** contestForm event 명 */
export const SET_INPUT = 'setInput';
export const SET_INPUT_ERROR = 'setInputError';
export const RESET_INPUT_ERROR = 'resetInputError';
export const DELETE_CONTEST_ITEMS = 'deleteContestItemIds';

/** 파일 카테고리 명 */
export const FILE_CATEGORY = {
  ID_PHOTO: 'ID_PHOTO',
  CERTIFICATE: 'CERTIFICATE',
  BUSINESS_REGISTRATION: 'BUSINESS_REGISTRATION',
  ENROLLMENT_VERIFICATION_LETTER: 'ENROLLMENT_VERIFICATION_LETTER',
  SMALL_BUSINESS_CONFIRMATION: 'SMALL_BUSINESS_CONFIRMATION',
};

/** 파일 카테고리 명, 카테고리 ID MAP */
export const FILE_CATEGORY_ID_MAPPING = {
  [FILE_CATEGORY.ID_PHOTO]: 'idPhoto',
  [FILE_CATEGORY.CERTIFICATE]: 'certificate',
  [FILE_CATEGORY.BUSINESS_REGISTRATION]: 'businessRegistration',
  [FILE_CATEGORY.ENROLLMENT_VERIFICATION_LETTER]: 'enrollmentVerificationLetter',
  [FILE_CATEGORY.SMALL_BUSINESS_CONFIRMATION]: 'smallBusinessConfirmation',
};

/** 파일 카테고리 PASCAL_CASE to map camelCase */
export const FILE_CATEGORY_CAMEL_CASE = Object.values(FILE_CATEGORY).reduce((obj, prev) => {
  obj[prev] = toCamelCase(prev);

  return obj;
}, {});

/** 한글 인풋명 **/
export const PLAYER_INPUT_KR = {
  associationName: '소속',
  name: '이름',
  birthday: '생년월일',
  phone: '연락처',
  address: '주소',
  addressDetail: '상세주소',
  password: '비밀번호',
  contestItemIds: '종목',
  note: '비고',
  ID_PHOTO: '증명사진',
  ENROLLMENT_VERIFICATION_LETTER: '재학증명서',
  CERTIFICATE: '자격증 또는 면허증 사본',
  BUSINESS_REGISTRATION: '사업자 등록증',
  SMALL_BUSINESS_CONFIRMATION: '미용사 면허증',
};

export const RECOMMENDER_INPUT_KR = {
  associationName: '소속',
  name: '이름',
  phone: '연락처',
};

/** 첨부파일 목록 */
export const ATTACHMENT_LIST = ['idPhoto', 'enrollmentVerificationLetter', 'certificate', 'businessRegistration', 'smallBusinessConfirmation'];

/** 전시 품목 */
export const EXHIBITION_ITEMS = [
  {
    title: '토탈뷰티',
    desc: '헤어, 스킨케어, 핸드&풋 바이케어, 피부미용기기, 염모제, 헤어기기, 전기아이롱, 헤어롤, 셋팅, 디지털 퍼머기 등 메이크업 제품, 네일 풋 케어용품, 두피케어, 헤어크리닉, 퍼품류, 방향용 제품류',
  },
  {
    title: '뷰티설비',
    desc: '헤어 피부 네일 및 풋 기기',
  },
  {
    title: '인테리어',
    desc: '토탈 살롱 인테리어 가구 및 설비',
  },
  {
    title: '교육기관, 홍보관',
    desc: '뷰티아카데미, 미용전문학교, 전문대학, 4년제 대학',
  },
  {
    title: '여성용품, 건강용품',
    desc: '헬스 휘트니스 & 다이어트 관련식품, 악세서리 및 란제리',
  },
  {
    title: '여행',
    desc: '국내외 미용박람회 소개 및 참관안내',
  },
  {
    title: '유통',
    desc: '미용유통, 언론계 국내외 미용잡지 및 매체',
  },
  {
    title: 'IT',
    desc: '고객관리 프로그램 및 전산기기시스템, 두피진단시스템, 기타 시뮬레이션 홈페이지',
  },
];
export const CONTEST = 'CONTEST';
export const CLASSIFICATION = 'CLASSIFICATION';
export const SECTOR = 'SECTOR';
export const EVENT = 'EVENT';

/** 대회 정보 */
export const contestInfoMap = {
  1: {
    title: '2022 국제한국미용페스티벌\nIKBF 2022',
    description:
      '국제한국미용페스티벌은 국내외 전문 미용인들의 정보교류 및 무역을 위한 대표적인 국내 최대의 미용 산업 전시회입니다.\n1998년 처음 개최된 이래, 매년 3만명의 국내외 미용인이 참관하는 국내 유일의 미용분야 전문 산업전시회 및 미용 축제의 장입니다.\n한국은 물론 아시아 미용 산업의 대표적인 마켓 플레이스로 자리매김 되고 있는 국제한국미용페스티벌에 여러분을 초대합니다.',
    sectionInfo1: '2022.12.06(화) ~ 12.07(수) | DCC대전컨벤션센터 제2전시장 1층',
    sectionInfo2: '주관 : 사)대한미용사회중앙회\n후원 : 보건복지부, 고용노동부, 대전시',
    download: require('@/assets/leaflet_compressed.pdf'),
  },
  12: {
    description: '대회 설명 작성 예정입니다.',
    sectionInfo1: '2024.11.26(화) 오전 8시 | 잠실 실내체육관',
    sectionInfo2: `주최 : 서울특별시
                    주관 : (사)대한미용사회 서울시협의회
                    후원 : (사)대한미용사회중앙회
                    문의 : 02)858-3393`,
    download: require('@/assets/leaflet_compressed.pdf'),
    poaster: require('@/assets/2024_contest_poster.pdf'),
    rule: require('@/assets/2024_contest_rules.pdf'),
    ruleFilename: '제8회 서울시 미용예술경연대회 경기세부규정_2024 Seoul Contest Rulebook',
    timeline: require('@/assets/2024_contest_schedule.pdf'),
    timelineFilename: '제8회 서울시 미용예술경연대회 전체 경기 일정',
  },
  13: {
    description: '대회 설명 작성 예정입니다.',
    sectionInfo1: '2024.12.02(월) ~ 12.03(화) 오전 8시 | DCC 대전컨벤션센터 제2전시장 1층',
    sectionInfo2: `주최 : 서울특별시
                    주관 : (사)대한미용사회중앙회
                    후원 : 대전시, 보건복지부, 고용노동부`,
    download: require('@/assets/leaflet_compressed.pdf'),
    poaster: require('@/assets/2024_global_contest_ikbf.pdf'),
    rule: require('@/assets/2024_ikbf_rule.pdf'),
    ruleFilename: 'IKBF2024 국제한국미용대회 경기세부규정_2024',
    timeline: require('@/assets/2024_ikbf_timeline.pdf'),
    timelineFilename: 'IKBF2024 국제한국미용대회 전체 경기 일정',
  },
};
