<template>
  <page doc-title="대회 등록" title-size="medium" main-width="4">
    <template #main-section="{ isMobileUi }">
      <circular-loader v-if="!isMounted" />
      <contest-form
        v-else
        :is-mobile-ui="isMobileUi"
        :is-disabled="isDisabled"
        :title="title"
        :button-text="buttonText"
        :default-player-info="playerInfo"
        :default-recommender-info="recommenderInfo"
        :is-student-form="registStatus.isStudentForm"
        :is-independent-business-form="registStatus.isIndependentBusinessForm"
        :submit-handler="validateHandler"
        :complete-handler="onCompleteHandler"
        :submit-player-info="submitPlayerInfo"
      />
      <contest-document-buttons :is-mobile-ui="isMobileUi" />
    </template>
  </page>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Page from '@/components/Page';
import CircularLoader from '@/components/CircularLoader.vue';
import ContestDocumentButtons from '@/containers/Contest/ContestDocumentButtons.vue';
import { ASSOCIATION_TYPE, PLAYER_INFO_TITLE, CONTEST_REGISTER_TYPE } from '@/constants/contest';

import ContestForm from '@/containers/ContestForm/ContestForm';

import { useContestStore, useLoadingStore, useUserStore } from '@/stores';
import { uploadPlayer } from '@/apis/player';
import { errorToast, infoToast, successToast } from '@/utils/toast';
import { convertDataForApi, validateFormData } from '@/utils/contest';
import { USER_ACTION_NAME } from '@/stores/user';

export default {
  name: 'ContestPlayerRegist',
  isMobileUi: {
    type: Boolean,
    default: false,
  },
  components: {
    Page,
    ContestForm,
    CircularLoader,
    ContestDocumentButtons,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const userStore = useUserStore();
    const loadingStore = useLoadingStore();
    const contestStore = useContestStore();

    const { contestRegisterType } = route.params;

    const isDisabled = ref(true);
    const isMounted = ref(false);

    const registStatus = ref({
      isStudentForm: false,
      isIndependentBusinessForm: false,
    });
    const buttonText = ref('잘못된 접근입니다');

    const playerInfo = ref({
      associationName: '',
      name: '',
      birthday: {
        year: '',
        month: '',
        day: '',
      },
      phone: '',
      businessName: '',
      address: '',
      addressDetail: '',
      zip: '',
      contestItemIds: [],
      note: '',
      contestRegisterType: contestRegisterType.replace('-', '_').toUpperCase(),
      associationType: ASSOCIATION_TYPE[contestRegisterType],
      regionName: '',
      branchOfficeId: '',
      contestId: contestStore.targetContestId,
      playerFiles: [],
      attachmentList: [],
      password: '',
    });

    const recommenderInfo = ref({
      associationName: '',
      branchOfficeId: '',
      name: '',
      phone: '',
      associationType: ASSOCIATION_TYPE[contestRegisterType],
      regionName: '',
    });

    const title = ref('');

    watch(
      () => loadingStore.getLoadings[USER_ACTION_NAME.REFRESH_LOGIN],
      (curLoading, prevLoading) => {
        if (!prevLoading || curLoading) return;
        if (contestRegisterType === CONTEST_REGISTER_TYPE.STUDENT) return;

        if (!userStore.checkLoginStatus()) {
          infoToast('소상공인부, 일반부는 로그인 후 지원 가능합니다.');
          router.push('/contest/1');
        }
      },
    );

    const initData = async () => {
      await contestStore.fetchContestPeriodStatus(playerInfo.value.contestId);

      if (contestStore.contestPeriodStatus.isRunContestApplication) {
        isMounted.value = true;
      } else {
        errorToast('접수기간이 아닙니다.');
        router.push('/contest/1');
      }
    };

    onMounted(() => {
      initData();

      if (!userStore.checkLoginStatus() && contestRegisterType !== CONTEST_REGISTER_TYPE.STUDENT) {
        infoToast('소상공인부, 일반부는 로그인 후 지원 가능합니다.');
        router.push('/contest/1');
      }

      registStatus.value.isStudentForm = contestRegisterType === CONTEST_REGISTER_TYPE.STUDENT;
      registStatus.value.isIndependentBusinessForm = contestRegisterType === CONTEST_REGISTER_TYPE.INDEPENDENT_BUSINESS;
      title.value = `선수 등록 (${PLAYER_INFO_TITLE[contestRegisterType]})`;
      buttonText.value = '등록';
      isDisabled.value = false;
    });

    const onCompleteHandler = () => {
      successToast('선수 등록이 완료되었습니다.');
      // TODO: fixme 하나은행과 협의 후 반영
      // router.push('/contest/1?regist=complete');
      router.push('/contest/1');
    };

    const validateHandler = async (playerInfo, recommender, isLogin) => {
      if (isLogin) {
        delete playerInfo.password;
      }
      const validInfo = validateFormData(playerInfo, recommender, isLogin, contestRegisterType);

      /** !isValid 면 인풋을 입력하라는 모달 표시 */
      if (!validInfo.isValid) {
        return validInfo;
      }

      return { isValid: true };
    };

    const submitPlayerInfo = async (playerInfo, recommender) => {
      const { convertedPlayerInfo, convertedRecommender } = convertDataForApi(playerInfo, recommender);

      const form = { ...convertedPlayerInfo, recommender: convertedRecommender };
      const formData = new FormData();

      formData.append('request', new Blob([JSON.stringify(form)], { type: 'application/json' }));
      form.attachmentList.forEach((item) => {
        formData.append('attachmentList', item);
      });

      await uploadPlayer(formData);
    };

    return { isMounted, playerInfo, recommenderInfo, title, buttonText, isDisabled, registStatus, validateHandler, onCompleteHandler, submitPlayerInfo };
  },
};
</script>
